import React from 'react';
import PageLayout from 'components/pageLayout';
import { Helmet } from 'react-helmet';
import AdminGroupBlock from 'components/block-elements/admin-group-block/admin-group-block';

export default function AdminPanelBlockPage(props) {
    const searchParams = new URLSearchParams(props.location.search);
    const searchText = searchParams.get('searchText') ?? '';
    const page = searchParams.get('page') ?? 0;

    return (
    <>
    <Helmet
    title='Admin Panel Groups'>

    </Helmet>
        <PageLayout>
            <AdminGroupBlock searchParams={props.location.search} page={page} searchText={searchText} />
        </PageLayout>
    </>
    );
};

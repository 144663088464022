import React, { useState, useEffect } from 'react';
import styles from './admin-group-block.module.less';
import GroupService from 'services/groups-service';
import GroupsBlock from '../groups-block/groups-block';
import { navigate } from 'gatsby';

export default function AdminGroupBlock({searchText, page, searchParams}) {

    const [filter, setFilter] = useState({
        page: page,
        search: searchText
    });
    const [groups, setGroups] = useState([]);
    const [groupSearch, setGroupSearch] = useState([]);
    const [pagesCount, setPagesCount] = useState(null);

    useEffect(() => {
        getGroups()
    }, [filter])

    const updateData = async() => {
        await getGroups();
    }

    const getGroups = async() => {
        await GroupService.GetAllGroups(filter)
            .then(res => {
                setGroups(res.data.data);
                setPagesCount(Array.from(Array(Math.ceil(res.data.total / 20)).keys()));
            }).catch(err => {
            });
    }

    const chagePage = async( event ) => {
        setFilter({ ...filter, page: event.target.name });
        let searchParam = new URLSearchParams(searchParams);
        searchParam.delete('page');
        searchParam.append("page", event.target.name);
        navigate(`/admin-panel/groups?${searchParam.toString()}`);
    }

    const searchClick = async() => {
        setFilter({ ...filter, page: 0, search: groupSearch });
        let searchParam = new URLSearchParams(searchParams);
        searchParam.delete('searchText');
        searchParam.append("searchText", filter.search);
        searchParam.delete('page');
        searchParam.append("page", filter.page);
        navigate(`/admin-panel/groups?${searchParam.toString()}`);
    }

    const clearSearch = async() => {
        setGroupSearch('');
        setFilter({ ...filter, page: 0, search: '' });
        let searchParam = new URLSearchParams(searchParams);
        searchParam.delete('searchText')
        searchParam.delete('page');
        navigate(`/admin-panel/groups?${searchParam.toString()}`);
    }

    const changeHandlerSearch = (event) => {
        setGroupSearch( event.target.value );
    }

    return <>
        <div className={styles.searchBlock}>
            <input
            name='searchText'
            placeholder='Search by group name or Id'
            value={groupSearch}
            onInput={changeHandlerSearch}
            className={styles.input}
            type='text'></input>
            <button className={styles.searchButton} onClick={() => searchClick()}>
                Search
            </button>
            <button disabled={!filter.search} onClick={() => clearSearch()}>Clear</button>
        </div>
        {groups?.length > 0 && groups.map(group => <GroupsBlock updateData={updateData} key={group.id} group={group}></GroupsBlock>)}
        <div className={styles.pageCountContainer}>
            {pagesCount?.length > 0 &&
                    pagesCount.map((el, i) => {
                        return (
                        <button
                            className={`${el == filter.page ? styles.activePagButton : ''} ${styles.button}`}
                            name={el}
                            onClick={chagePage}>
                            {el + 1}
                        </button>
                        );
                    })}
        </div>
    </>
}
import React, { useState } from "react";
import styles from './groups-settings-modal.module.less';
import GroupService from 'services/groups-service';

export default function GroupsSettingsModal({ closeModal, updateData, group }) {

    const [groupClone, setGroupClone] = useState(group);
    
    const save = async() => {
        const model = {
            id: groupClone.id,
            displayName: groupClone.displayName,
            hideInTable: groupClone.hideInTable,
            teamTableColors: groupClone.teamTableColors
        };

        await GroupService.ChangeGroupAsync(model);

        updateData();
        closeModal();
        
    }

    const changeHandlerDisplayName = (e) => {
        setGroupClone({ ...groupClone, displayName: e.target.value })
    }

    const setStartTeamPosition = (e, index) => {
        let colors = groupClone.teamTableColors;

        colors.map((el, i) => 
            i == index ? el.teamsStart = e.target.value : el
        );

        setGroupClone({ ...groupClone, teamTableColors: colors });
    }

    const setTournamentTeamName = (e, index) => {
        let colors = groupClone.teamTableColors;

        colors.map((el, i) => 
            i == index ? el.tournamentName = e.target.value : el
        );

        setGroupClone({ ...groupClone, teamTableColors: colors });
    }

    const setEndTeamPosition = (e, index) => {
        let colors = groupClone.teamTableColors;

        colors.map((el, i) => 
            i == index ? el.teamsEnd = e.target.value : el
        );

        setGroupClone({ ...groupClone, teamTableColors: colors });
    }

    const setTeamColor = (e, index) => {
        let colors = groupClone.teamTableColors;

        colors.map((el, i) => 
            i == index ? el.color = e.target.value : el
        );

        setGroupClone({ ...groupClone, teamTableColors: colors });
    }

    const addTeamColor = () => {
        let colors = groupClone.teamTableColors;
        colors.push({
            color: '',
            leagueName: groupClone.id,
            teamsEnd: 0,
            teamsStart: 0,
            tournamentName: ''
        })

        setGroupClone({ ...groupClone, teamTableColors: colors });
    }

    const deleteElem = (index) => {
        let colors = groupClone.teamTableColors;
        colors.splice(index, 1);

        setGroupClone({ ...groupClone, teamTableColors: colors });
    }

    return<>
        <div className={styles.closeButtonBlock}>
            <button onClick={closeModal} className={styles.closeButton}>X</button>
        </div>
        <div className={styles.mainContainer}>
                <div className={styles.childContainer}>
                    <span>Group Id</span>
                    <input
                        name='League Id'
                        value={groupClone.id}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Group Name</span>
                    <input
                        name='League Name'
                        value={groupClone.name}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    <span>Group Display Name</span>
                    <input
                        name='League Display Name'
                        value={groupClone.displayName}
                        className={styles.input}
                        onChange={changeHandlerDisplayName}
                        type='text'>
                    </input>
                    <span>Group Year</span>
                    <input
                        name='League Country Code'
                        value={groupClone.year}
                        readOnly
                        className={styles.input}
                        type='text'>
                    </input>
                    {/* <input type="checkbox" id={"HideHiddenPlayersCheckbox"}  className={styles.missingPlayerCheckboxHideHiddenPlayers} defaultChecked={teamsInfo.hideHiddenPlayers} onChange={(e) => {
                    setTeamsInfo({...teamsInfo, hideHiddenPlayers: !teamsInfo.hideHiddenPlayers})
                }} />
                <label htmlFor="HideHiddenPlayersCheckbox" className={styles.missingPlayerLabelHideHiddenPlayersLabel} >{teamsInfo.hideHiddenPlayers ? "Visa dolda spelare" : "Dölj dolda spelare"}</label> */}
                <div style={{marginTop: '14px'}}>
                        <span>Tournament Table Colors</span>
                        {groupClone.teamTableColors.length > 0 && 
                            groupClone.teamTableColors.map( (el, index) => <div style={{marginBottom: '4px'}} key={index}>
                                <input className={styles.lowWidthInput} type="number" placeholder="Start place" value={el.teamsStart} onChange={(e) => setStartTeamPosition(e, index)}></input>
                                <input className={styles.lowWidthInput} type="number" placeholder="End place" value={el.teamsEnd} onChange={(e) => setEndTeamPosition(e, index)}></input>
                                <input type="text" placeholder="Next step Tournamnet name" value={el.tournamentName} onChange={(e) => setTournamentTeamName(e, index)}></input>
                                <input className={styles.width100} type="text" placeholder="Color" style={{background: el.color}} value={el.color} onChange={(e) => setTeamColor(e, index)}></input>
                                <button style={{width: '61px'}} onClick={() => deleteElem(index)}>Del</button>
                            </div>) }
                            <div><button style={{width: '100%'}} onClick={addTeamColor}>Add</button></div>
                    </div>
                <div>
                    <input type="checkbox" id={"showInTableCheckbox"}  className={styles.missingPlayerCheckboxHideHiddenPlayers} defaultChecked={groupClone.hideInTable}  onChange={() => {
                        setGroupClone({ ...groupClone, hideInTable: !groupClone.hideInTable })
                    }}/>
                    <label htmlFor="showInTableCheckbox" className={styles.missingPlayerLabelHideHiddenPlayersLabel} >Hide in Table</label>
                </div>
                    <button onClick={save} className={styles.saveButton}>Save</button>
                    {/* <Toaster position='top-left' reverseOrder={true} /> */}
                </div>
            </div>
    </>
}
import axios from 'components/helpers/axios';

const url = '/api/groups';

class GroupsService {
    GetAllGroups( filter ){
        return axios.get(`${url}/getAllGroups`, { params: filter } );
    }

    ChangeGroupAsync( model ) {
        return axios.post(`${url}/changeGroupInfo`, model);
    }

    GetGroupsListForTable(sportId) {
        return axios.get(`${url}/groupListForTable?sportId=${sportId}`);
    }

}

const singleton = new GroupsService();
export default singleton;
